import axios from 'axios';
import { cache } from '@/utils';

export function httpRequest(options) {
  let queryObj = {
    url: options.url,
    method: options.method || 'get',
    headers: options.headers || {},
    responseType: options.responseType || 'json',
  };

  const headers = {
    'X-Country': options['CountryCode'] || options?.country?.code || 'US',
    'X-Language': options['X_Language'] || options?.country?.X_Language || 'en_us',
  };

  const instaToken = cache.getCookie('insta_access_token');

  if (!!options.instaToken && instaToken) {
    queryObj.headers['X-User-Token'] = instaToken;
  }

  if (!!options.data) {
    queryObj.data = options.data;
  }

  if (!!options.params) {
    queryObj.params = options.params;
  }

  queryObj.params = Object.assign(options.params || {}, headers);

  return new Promise((resolve, reject) =>
    axios(queryObj)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      })
  );
}
